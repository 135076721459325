<template>
    <div>
        <el-popover
            placement="bottom"
            trigger="click"
            v-model="show"
            :disabled="rulesForm.status == '2'"
            popper-class="personTree"
        >
            <div v-show="show" class="treeWrapper">
                <div class="content">
                    <div class="top-info">
                        <span>选择名称</span>
                    </div>
                    <el-input v-model="filterText" placeholder="输入名称" class="filter-input" clearable
                        :validate-event="false" />
                    <div>
                        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="allOpt" style="margin-left: 24px">全选</el-checkbox>
                    </div>
                    <el-tree ref="treeRef" show-checkbox :data="treeData" node-key="id" :props="data.defaultProps"
                        :filter-node-method="filterNode" :default-expand-all="isShowTree" @check="handleCheck" class="tree" />
                </div>
                <div class="content">
                    <div class="top-info">
                        <el-button type="text" style="float: right" @click="clear">清空</el-button>
                    </div>
                    <div class="list">
                        <p v-for="(item,index) in checkedList" :key="item.value" class="item" @mouseleave="showIndex = ''"
                            @mouseenter="showIndex = index">
                            <span>{{ item.label }}</span>
                            <i v-show="showIndex === index" class="el-icon-close" @click="del(index)" />
                        </p>
                    </div>
                </div>
                <div class="btn" style="text-align: right;padding: 0 10px 10px">
                    <el-button @click="handleCancel">取消</el-button>
                    <el-button type="primary" @click="save">确定</el-button>
                </div>
            </div>
            <div slot="reference" class="custom-input" :class="rulesForm.status == '2' ? 'disabled-input' : ''">
                <span v-if="inputValue == ''" style="color: #C0C4CC">请选择</span>
                <div v-else class="person">
                    <span v-for="(item, index) in inputValue.split(',')" :key="index" ref="J_refs">{{ index < inputValue.split(',').length - 1 ? item + ',' : item }}</span>
                </div>
                <span v-if="nums" class="nums"> {{ '+' + nums }}</span>
            </div>
        </el-popover>
    </div>
</template>

<script>

export default {
    props: {
        data: {
            type: Object
        },
        rulesForm: {
            type: Object
        },
        treeData: {
            type: Array
        },
        isShowTree:{
          type:Boolean,
          default:false
        },
        flag: {
            type: Boolean
        },
        type: {
            type: String
        }
    },
    data() {
        return {
            show: false,
            showIndex: '',
            filterText: '',
            checkedList: [],
            inputValue: '',
            treeLength:0,
            checkAll: false,
            isIndeterminate: true,
            nums: 0
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.inputValue = this.rulesForm.customPushName
            this.allOptRecursion(this.treeData)
            this.checkedList = []
        })
    },
    watch: {
        filterText(val) {
            this.$refs.treeRef.filter(val);
        },
        show(bool) {
            this.$nextTick(() => {
                bool && this.handleClick()
            })
        }
    },
    methods: {
        handleCheck() {
            let checkedNodes = this.$refs.treeRef.getCheckedNodes(true).filter(i => !i.hasOwnProperty('organType'))
            console.log('节点---------------', checkedNodes)
            this.checkedList = checkedNodes.map(i => ({ label: i.name, value: i.id }))
            this.checkAll = checkedNodes.length == this.treeLength
            this.isIndeterminate = checkedNodes.length > 0 && checkedNodes.length < this.treeLength
        },
        save() {
            this.show = false
            this.inputValue = Array.from(new Set(this.checkedList.map(i => i.label))).toString()
            this.rulesForm[this.data.key] = this.checkedList.map(i => i.value).toString()
            this.$emit('saveSelectList', this.data.key, this.inputValue, this.type)
            let count = 0;
            let width = 0;
            let bool = true
            this.$nextTick(() => {
                let len = Array.prototype.slice.call(this.$refs.J_refs).length;
                this.$refs.J_refs.map((item, index) => {
                    width += Number(item.offsetWidth);
                    console.log(width,'width')
                    if (width > 277 && bool) {
                        count = index;
                        bool = false;
                    }
                })
                if (count !== 0) {
                    this.nums = len - count
                } else {
                    this.nums = 0;
                }
                
                console.log(this.nums,'count')
            })
            
        },
        handleClick() {
            this.filterText = ''
            console.log('获取到的id', this.rulesForm[this.data.key])
            this.$refs.treeRef.setCheckedKeys(this.rulesForm[this.data.key].split(','))
            this.handleCheck()
            // this.show = true
        },
        handleCancel() {
            this.show = false
        },
        // 删除选中
        del(index) {
            this.checkAll = false,
            this.isIndeterminate = true
            this.checkedList.splice(index, 1)
            if (this.checkedList.length == 0) {
                this.isIndeterminate = false
            }
            this.$refs.treeRef.setCheckedKeys(this.checkedList.map(i => i.value))
        },
        filterNode(value, data) {
            if (!value) return true;
            return data[this.data.defaultProps.label].indexOf(value) !== -1;
        },
        // 清空选中
        clear() {
            this.checkAll = false,
            this.isIndeterminate = false
            // 清空数据
            this.checkedList = []
            this.$refs.treeRef.setCheckedKeys([])
        },
        // 全部选中
        allOpt() {
            this.isIndeterminate = false
            if(this.checkedList.length == this.treeLength){
                this.clear()
                return
            }
            if (this.treeData.length > 0) {
                this.checkedList = []
                this.allOptRecursion(this.treeData)
                this.$refs.treeRef.setCheckedKeys(this.checkedList.map(v => {
                    return v.value
                }))
            }
        },
        // 递归树形菜单最底层
        allOptRecursion(data) {
            this.treeLength = 0
            data.forEach(v => {
                if (v.children && v.children.length != 0) {
                    this.allOptRecursion(v.children)
                } else if (!v.hasOwnProperty('organType')) {
                    this.checkedList.push({ label: v.name, value: v.id })
                }
            })
            this.treeLength = this.checkedList.length
        }
    }
}
</script>
<style lang="scss" scoped>
.treeWrapper {
    box-sizing: border-box;
    width: 500px;
    margin-top: 12px;
    border: 1px solid #E4E7ED;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    .top-info {
        padding: 0 20px;
        background-color: #f5f7fa;
        height: 35px;
        line-height: 35px;
        font-size: 13px;
        color: #909399;
    }

    .filter-input {
        margin: 10px;
        width: 205px;
    }

    .content {
        box-sizing: border-box;
        margin: 10px;
        border: 1px solid #E4E7ED;
        border-radius: 3px;
        float: left;
        height: 300px;
        width: 228px;

        .tree {
            height: 188px;
            overflow: auto;
        }

        .list {
            height: 261px;
            overflow: auto;
            width: 100%;

            .item {
                font-size: 14px;
                color: #606266;
                height: 32px;
                line-height: 32px;
                padding: 0 20px;

                &:hover {
                    background-color: #f5f7fa;
                }
            }

            .el-icon-close {
                color: #409EFF;
                font-weight: 1000;
                float: right;
                line-height: 32px;
                cursor: pointer;
            }
        }
    }
    
}
.custom-input {
    width: 360px;
    height: 32px;
    position: relative;
    border: 1px solid #ccc;
    box-sizing: border-box;
    padding: 0 60px 0 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    .person {
        max-width: 277px;
        padding: 3px 3px 3px 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        background-color: #f4f4f5;
        border-color: #e9e9eb;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
        border-radius: 5px;
    }
    .nums {
        position: absolute;
        display: block;
        height: 22px;
        line-height: 22px;
        padding: 0 8px;
        top: 4px;
        right: 18px;
        background-color: #f4f4f5;
        border-color: #e9e9eb;
        color: #909399;
        font-size: 12px;
        border-radius: 4px;
    }
}
.disabled-input {
    cursor: not-allowed;
}
</style>
<style>
    .personTree {
        padding: 0;
        border: none;
    }
</style>
